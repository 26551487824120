//
// Masthead section styling
//

header.masthead {
    position: relative;
    height: 38vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    color: $white;
    padding-top: calc(6rem + 4.5rem);
    padding-bottom: 6rem;
    text-align: center;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: 1;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        object-fit: cover;
    }

    .masthead-subheading {
        font-size: 1.5rem;
        font-style: italic;
        line-height: 1.5rem;
        margin-bottom: 25px;
        font-family: $subheadings-font-family;
    }

    .masthead-heading {
        font-size: 3.25rem;
        font-weight: 700;
        line-height: 3.25rem;
        margin-bottom: 2rem;
        font-family: $headings-font-family;
    }
}

@include media-breakpoint-up(md) {
    header.masthead {
        padding-top: 12.5rem + 4.5rem;
        padding-bottom: 12.5rem;

        .masthead-subheading {
            font-size: 2.25rem;
            font-style: italic;
            line-height: 2.25rem;
            margin-bottom: 2rem;
        }

        .masthead-heading {
            font-size: 4.5rem;
            font-weight: 700;
            line-height: 4.5rem;
            margin-bottom: 4rem;
        }
    }
}
