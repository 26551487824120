//
// Contact section styling
//

section#contact {
    background-color: $gray-900;
    background-image: url("../assets/img/map-image.png");
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 8rem;
    padding-bottom: 6rem;

    .section-heading {
        color: $white;
    }
}
